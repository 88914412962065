<template>
  <b-row class="flex">
      <b-col md="5">
          
        <b-overlay :show.sync="loadingAddEditRole" rounded>
            <b-card>
                <h3>{{modeEdit ? $t('tabs.editrol') : $t('tabs.addrol')}}</h3>
                <b-form-group
                    label="Name"
                    label-for="name"
                >
                    <template #label>{{ $t('labels.name')}}</template>
                    <b-form-input
                    id="name"
                    v-model="roleData.name"
                    /> 
                </b-form-group>
                <b-form-group
                    label="Description"
                    label-for="description"
                >
                    <template #label>{{ $t('labels.description')}}</template>
                    <b-form-input
                    id="description"
                    v-model="roleData.description"
                    /> 
                </b-form-group>

                <div class="d-flex  justify-content-between">
                    <!-- add cancel -->
                    <b-button variant="danger" v-if="modeEdit">
                        <feather-icon icon="XIcon" class="mr-1" />
                        <span class="text-nowrap">{{$t('buttons.cancel')}}</span>
                    </b-button>
                    <!-- add save -->
                    <b-button @click="saveUpdate()" variant="primary">
                        <!-- icon check -->
                        <feather-icon :icon="modeEdit ? 'EditIcon' : 'SaveIcon'" class="mr-1" />
                        <span class="text-nowrap">{{modeEdit ? $t('buttons.update') : $t('buttons.save')}}</span>
                    </b-button>
                </div>
            </b-card>
        </b-overlay>
      </b-col>
      <b-col md="7">
        <b-overlay :show.sync="loadingListRoles" rounded>
            <b-card>
                <b-table 
                responsive="sm" 
                :items="rolesList" 
                :fields="fields">
                    <!-- actions -->
                    <template #cell(actions)="data">
                        <div class="d-flex">
                            <b-button 
                                v-if="!data.item.isSuper"
                                @click="$router.push({name: 'permissions-roles', params: {id: data.item._id}})" 
                                size="sm" variant="warning" 
                                class="mr-1"
                                v-b-tooltip.hover.top="$t('tooltips.assignpermissions')"
                            >
                                <feather-icon icon="TagIcon"/>
                            </b-button>
                            <b-button 
                                v-if="!data.item.isSystem"
                                @click="methoEdit(data.item)" 
                                size="sm" 
                                variant="warning" 
                                class="mr-1"
                                v-b-tooltip.hover.top="$t('tooltips.edit')"
                            >
                                <feather-icon icon="EditIcon"/>
                            </b-button>
                            <b-button
                                v-if="!data.item.isSystem"
                                @click="deleteRole(data.item)" 
                                size="sm" 
                                variant="danger"
                                v-b-tooltip.hover.top="$t('tooltips.delete')"
                            >
                                <feather-icon icon="TrashIcon"/>
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </b-card>
        </b-overlay>
      </b-col>
  </b-row>
</template>

<script>
import { BTable,
        BDropdown,
        BDropdownItem,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BFormGroup,
        VBTooltip,
        BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BTable,
        BDropdown,
        BDropdownItem,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BFormGroup,
        VBTooltip,
        BOverlay,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },

    data(){
        return{
            loadingListRoles: false,
            loadingAddEditRole: false,
            fields: [
                {key: 'name', label: this.$t('labels.name') },
                {key: 'description', label: this.$t('labels.description') },
                {key: 'actions', label: this.$t('labels.actions'), sortable: false, width: '150px',}
            ],
            items: [],
            rolesList: [],
            modeEdit: false,
            roleData: {
                name: '',
                description: '',
            },
        
        }
    },
    methods: {
        async getRoles(){
            try {
                this.loadingListRoles = true
              const {data} = await this.$store.dispatch('moduleRoles/getRoles')
              this.rolesList = data.roles
            } catch (error) {
                console.log('error', error)
            }finally{
                this.loadingListRoles = false
            }
        },
        methoEdit({_id, name, description }){
            this.roleData = {
                _id,
                name: name,
                description: description,
            }
            this.modeEdit = true
            console.log('methoEdit', name)
        },
        clearInputs(){
            this.roleData = {
                name: '',
                description: '',
            }
        },
        async deleteRole({_id}){
         const response = await this.$bvModal.msgBoxConfirm(this.$t('rols.comfirmDelete'), {
                title: this.$t('rols.pleaseConfirm'),
                size: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('buttons.yes'),
                cancelTitle: this.$t('buttons.no'),
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            if(response){
                try {
                 await this.$store.dispatch('moduleRoles/deleteRole', _id)
                 this.roleData = {
                    name: '',
                    description: '',
                }
                 this.getRoles()
                } catch (error) {
                    console.log('error', error)
                }
            }
        },
        async saveUpdate() {
            try {
                this.loadingAddEditRole = true
                if(this.modeEdit){
                    await this.$store.dispatch('moduleRoles/updateRole', this.roleData)
                    this.modeEdit = false
                }else{
                    await this.$store.dispatch('moduleRoles/addRole', this.roleData)
                }
                this.getRoles()
                this.clearInputs()
                
            } catch (error) {
                console.log(error)
            }finally{
                this.loadingAddEditRole = false
            }
        },
    },
    created(){
        this.getRoles()
    },
}
</script>

<style>
div.content-header {
    display: none;
}

</style>